// Google Analytics using Autotrack plugin
import 'autotrack/lib/plugins/event-tracker';
import 'autotrack/lib/plugins/url-change-tracker';
import 'autotrack/lib/plugins/outbound-link-tracker';
import 'autotrack/lib/plugins/clean-url-tracker';

window.ga('create', 'UA-2899718-7', 'auto');
window.ga('require', 'eventTracker');
window.ga('require', 'outboundLinkTracker');
window.ga('require', 'urlChangeTracker');
window.ga('send', 'pageview');