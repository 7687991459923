import axios from 'axios';

function configureRequest(apiPath) {
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  return window.frontendConfig.api + apiPath;
}

export function GETrequest(apiPath, config = {}) {
  const endPoint = configureRequest(apiPath);
  return axios.get(endPoint, config)
    .then(res => res)
    .catch(err => err.response);
}

export function POSTrequest(apiPath, data, config = {}) {
  const endPoint = configureRequest(apiPath);
  return axios.post(endPoint, data, config)
    .then(res => res)
    .catch(err => err.response);
}